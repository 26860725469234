<template>
  <v-card class="course pa-3 mb-3 justify-center" elevation="5">
      <v-layout row wrap class="pa-3" justify-center align-center>
        <v-flex xs12 sm6 md2 class="pa-3 title">
          <div class="teal--text mb-1">{{ $t('instructors.coursesTab.courseTitle') }}</div>
          <div>{{ course.course_title }}</div>
        </v-flex>

        <v-flex xs12 sm6 md2 class="py-3">
          <div class="teal--text mb-1">{{ $t('instructors.coursesTab.coursePrice') }}</div>
          <div class="pr-4">{{ course.course_price }}</div>
        </v-flex>

        <v-flex xs12 sm6 md2 class="py-3">
          <div class="teal--text mb-1">{{ $t('instructors.coursesTab.courseType') }}</div>
          <div>{{ course.course_type }}</div>
        </v-flex>

        <v-flex xs12 sm6 md2 class="py-3">
          <div class="teal--text mb-1">{{ $t('instructors.coursesTab.categoryTitle') }}</div>
          <div>{{ course.category_title }}</div>
        </v-flex>

        <!-- Change depending on whether the courses are pending or regular -->
        <v-flex xs12 sm6 md2 v-if="!this.pending" class="py-3">
          <div class="teal--text mb-1">{{ $t('instructors.coursesTab.totalEnrolls') }}</div>
          <div>{{ course.total_enrolls }}</div>
        </v-flex>

        <v-flex xs12 sm6 md2 v-else class="py-3">
          <div class="teal--text mb-1">{{ $t('instructors.coursesTab.submitDate') }}</div>
          <div>{{ course.submit_date | moment }}</div>
        </v-flex>

        <!-- Change depending on whether the courses are pending or regular -->
        <v-flex xs12 sm6 md2 v-if="!this.pending" class="py-3">
          <div class="teal--text mb-1">{{ $t('instructors.coursesTab.totalCompleted') }}</div>
          <div>{{ course.total_completed }}</div>
        </v-flex>

        <v-flex xs12 sm6 md2 v-else class="d-flex flex-column align-center justify-center" >

          <v-btn class="ma-1 white--text [" small @click="$emit('confirm')" :color="primaryColor">
            <v-card-text>{{ $t('courseStatus.accept') }}</v-card-text>
          </v-btn>
          <v-btn class="ma-1 white--text px-0" small @click="$emit('resubmit')" :color="YColor">
            <v-card-text>{{ $t('courseStatus.resubmit') }}</v-card-text>
          </v-btn>
          <v-btn class="ma-1 white--text" small @click="$emit('reject')" :color="RColor">
            <v-card-text>{{ $t('courseStatus.reject') }}</v-card-text>
          </v-btn>

        </v-flex>
      </v-layout>
      <!-- <v-divider></v-divider> -->
    </v-card>
</template>
<script>
import moment from "moment";

export default {
  name: 'Course',
  props: [
    'course',
    'pending'
  ],
  filters: {
    moment: function(date) {
      return moment(date).format("YYYY-MM-DD");
    }
  }
}
</script>
<style lang="scss" scoped>
.delete-icon:hover {
  cursor: pointer;
}

.course:nth-of-type(even) {
  border-left: 4px gray solid;
  border-right: 4px gray solid;
}
.course:nth-of-type(odd) {
  border-left: 4px teal solid;
  border-right: 4px teal solid;
}
</style>