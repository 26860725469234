<template>
  <div>
    <notifications group="public" position="bottom center"  :style="{marginBottom:'2px'}"/>
    <v-container>
      <v-row class="d-flex align-center">
        <h1>
          <base-arrow-back-icon @click="$router.go(-1)">{{ this.rtl ? rightArrowIcon  :leftArrowIcon }}</base-arrow-back-icon>
          {{ $t('instructors.instructorInfo') }}
        </h1>
      </v-row>
    </v-container>
    <v-card>
      <v-container>
        <v-row justify="center">
          
          <v-col lg="12" sm="12" cols="12">
            <v-tabs
              v-model="tab"
              :background-color="this.grayColor"
              :color="this.primaryColor"
              centered
              mobile-breakpoint="md"
              grow
              height="90"
              loading
            >
            <v-tabs-slider></v-tabs-slider>
              <v-tab
                href="#basic-info"
                class="subtitle-1"
              >
                <v-icon large left>{{ personalIcon }}</v-icon>
                {{ $t('instructors.personalInfo') }}
              </v-tab>
              
              <v-tab
                v-if="!isCreate"
                href="#courses"
                class="subtitle-1"
              >
                <v-icon large left>{{ coursesIcon }}</v-icon>
                {{ $t('instructors.courses') }}
              </v-tab>

              <v-tab
                v-if="!isCreate"
                href="#pendingCourses"
                class="subtitle-1"
              >
                <v-badge
                  :color="this.primaryColor"
                  :content="getPendingCoursesCount"
                >
                  <v-icon large left>{{ pendingIcon }}</v-icon>
                  {{ $t('instructors.pendingCourses') }}
                </v-badge>
              </v-tab>

              <v-tab
                v-if="!isCreate"
                href="#students"
                class="subtitle-1"
              >
                <v-icon large left>{{ studentsIcon }}</v-icon>
                {{ $t('instructors.students') }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item
                value="basic-info"
              >
                <BasicInfo :form="form" :isView="isView" :isEdit="isEdit" :isCreate="isCreate"/>
              </v-tab-item>
              <v-tab-item v-if="!isCreate" value="courses">
                <Courses :pending="false"/>
              </v-tab-item>
              <v-tab-item v-if="!isCreate" value="pendingCourses">
                <Courses :pending="true"/>
              </v-tab-item>
              <v-tab-item v-if="!isCreate" value="students">
                <Students />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import BaseArrowBackIcon from "../../../../../core/Base/Arrows/BaseArrowBackIcon";
import BasicInfo from './BasicInfo'
import Courses from './Courses/index'
import Students from './Students/index'

export default {
  name: "InstructorInfo",
  components: { BaseArrowBackIcon, BasicInfo, Courses, Students },
  data() {
    return {
       form: {},
       tab: null,
    };
  },
  created() {
    if (!this.isCreate) {
      this.fetchPendingCoursesCount(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions("Instructors/Courses", ["fetchPendingCoursesCount"]),
  },
  computed :{
    ...mapGetters("Instructors/Courses", [ "getPendingCoursesCount"]),

    ...mapState(["rtl"]), // to decide the direction of the arrow

    isEdit () {
      return this.$route.query.type == 'edit'
    },
    isView () {
      return this.$route.query.type == 'view';
    },
    isCreate () {
      return this.$route.query.type == 'new'
    },
  }
}
</script>

<style scoped>

</style>
