<template>
  <v-container class="d-flex flex-column justify-center">
    <v-progress-linear
      v-show="loading"
      indeterminate
      :color="this.primaryColor"
    ></v-progress-linear>
    <SearchItems class="align-self-center" @search="search" @undoSearch="fetch"/>
    <Student 
    v-for="student in this.students" :key="student.student_id"
    :student="student"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Student from './Components/Student'
import SearchItems from '../../Components/SearchItems'

export default {
  name: 'Students',
  data () {
    return {
      loading: false,
      students: []
    }
  },
  components: {
    Student,
    SearchItems
  },
  created () {
    this.fetch()
  },
  methods: {
    ...mapActions("Instructors/Students", ["fetchStudents", "fetchStudentsWithSearch"]),

    fetch () {
      this.loading = true
      this.fetchStudents(this.$route.params.id).then(() => {
        this.students = this.getStudents
        this.loading = false
      })
    },

    search (payload) {
      console.log(payload)
      this.fetchStudentsWithSearch({ searchTerm: payload.searchTerm, id: this.$route.params.id }).then(() => {
        this.students = this.getStudents
      })
    }
  },
  computed: mapGetters("Instructors/Students", ["getStudents"])
}
</script>