<template>
  <v-form 
    @submit="$emit('search', { searchTerm: searchTerm })"  
    class="p-2 d-flex pa-5"
      lazy-validation 
    v-on:submit.prevent
    >
      <v-text-field class="color text-lg pa-0 ma-2" :label="$t('navBar.search')" v-model="searchTerm"></v-text-field>
      <v-btn v-if="searchTerm" type="submit" depressed text :color="this.whiteColor" class="search pa-2 color">
        <v-icon large>{{ searchIcon }}</v-icon>
      </v-btn>
      <v-btn v-if="searchTerm" @click="undo()" depressed text :color="this.whiteColor" class="undo pa-2 color">
        <v-icon large>{{ undoIcon }}</v-icon>
      </v-btn>
    </v-form>
</template>
<script>
export default {
  name: "SearchCourses",
  data () {
    return {
      searchTerm: ''
    }
  },
  methods: {
    undo () {
      this.searchTerm = ''
      this.$emit('undoSearch')
    }
  }
}
</script>
<style lang="scss" scoped>
.color {
  color: teal !important;
}

.undo, .search {
  animation: enterUndo 0.4s linear forwards;
}

@keyframes enterUndo {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-60px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}
</style>