<template>
  <v-row justify="space-around" class="mt-3">
    <v-progress-linear
      class="mt-4"
      v-show="loading"
      indeterminate
      :color="this.primaryColor"
    ></v-progress-linear>
    <v-col cols="12" sm="12" md="4">
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <base-avatar>
            <v-img :src="getImg" max-width="100%" min-height="150">
            </v-img>
          </base-avatar>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-file-input
            v-if="!isView"
            accept="image/*"
            :label="$t('form.avatar')"
            :prepend-icon="cameraIcon"
            v-model="newAvatar"
            :rules="requiredRule"
          ></v-file-input>
        </v-col>
      </v-row>
    </v-col>
    
    <v-col cols="12" sm="12" md="4">
      <v-form ref="form">
        <v-text-field
          v-model="form.firstName"
          :label="$t('form.firstName')"
          :readonly="isView"
          :rules="requiredRule"
        ></v-text-field>

        <v-text-field
          v-model="form.lastName"
          :label="$t('form.lastName')"
          :readonly="isView"
          :rules="requiredRule"
        ></v-text-field>
        
        <v-text-field
          v-model="form.email"
          :label="$t('form.email')"
          :readonly="isView"
          :rules="emailRules"
        ></v-text-field>

        <v-text-field
          v-if="this.isCreate"
          v-model="form.password"
          type="password"
          :label="$t('form.password')"
          :readonly="isView"
          :rules="passwordRules"
        ></v-text-field>

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="form.birth_date"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.birthDate"
              :label="$t('form.birthDate')"
              :readonly="isView"
              v-bind="attrs"
              v-on="on"
              :rules="requiredRule"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.birthDate"
            no-title
            scrollable
            :readonly="isView"
            color="#69d1b6"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="menu = false"
            >
              {{ $t('form.cancel') }}
            </v-btn>
            <v-btn
              color="#69d1b6"
              @click="$refs.menu.save(form.birthDate)"
              dark
            >
              {{ $t('form.ok') }}
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-text-field
            v-model="form.phone"
            placeholder="+96399229922"
            :label="$t('form.phone')"
            :readonly="isView"
            :rules="requiredRule"
          ></v-text-field>

          <v-select :readonly="isView" v-model="form.country" :items="countries" :label="$t('form.country')"
          :rules="requiredRule">
          </v-select>

          <v-text-field
          v-model="form.identityNumber"
          :label="$t('form.idNumber')"
          :readonly="isView"
          :rules="requiredRule"
        ></v-text-field>
      </v-form>
    </v-col>

    <v-col cols="12" md="12" sm="12">
      <v-row>
        <v-col cols="12" sm="12" lg="5">
        </v-col>

        <v-col cols="12" sm="12" lg="6" class="d-flex justify-center align-center">
          <v-row class="d-flex justify-center align-center mb-1">
            <v-col v-if="isEdit" cols="12" lg="6" sm="12">
              <base-button @click="deleteConfirm = $route.params.id" v-if="!isView" :options="{block : true, color: RColor}">{{ $t('instructors.delete') }}</base-button>
            </v-col>
            
            <v-col cols="12" lg="6" sm="12">
                <base-button @click="saveInstructor()" v-if="!isView" :options="{ block : true, color: BColor }">{{ $t('form.save') }}</base-button>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <confirm-delete
      v-if="deleteConfirm >= 0"
      v-bind="{ id: deleteConfirm, isOpen: true }"
      @handleClose="deleteConfirm = -1;"
      @handelRequest="removeInstructor"
    ></confirm-delete>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BaseAvatar from "../../../../../core/Base/Avatars/BaseAvatar";
import BaseButton from "../../../../../core/Base/Buttons/BaseButton";
import ConfirmDelete from "../../../Components/confirmDelete";

export default {

  name: 'basicInfo',

  components: {
    BaseAvatar,
    BaseButton,
    ConfirmDelete
  },

  props: [
    'isView',
    'isCreate',
    'isEdit'
  ],

  data () {
    return {
      emailRules: [
          v => !!v || this.$t('required.email'),
          v => /.+@.+/.test(v) || this.$t('required.validEmail'),
      ],
      passwordRules: [
          v => !!v || this.$t('required.password'),
          v => v.length >= 10 || this.$t('required.validPassword'),
      ],
      requiredRule: [(v) => !!v || this.$t('required.field')],
      newAvatar: null,
      form: {
        firstName: '',
        lastName: '',
        birth_date: null,
        avatar: null,
        country: '',
        identity_number: ''
      },
      menu: false,
      date: null,
      countries: ['Syria', 'Lebanon', 'UAE'],
      deleteConfirm: -1,
      loading: false
    }
  },

  created () {
    this.initialFetch();
  },

  methods: {
    ...mapActions("Instructors", ["addInstructor", "updateInstructor", "deleteInstructor", "fetchSingleInstructor"]),

    initialFetch () {
      if(!this.isCreate) {
      this.loading = true;
      this.fetchSingleInstructor(this.$route.params.id).then(() => {
        this.form = this.getSingleInstructor
        console.log(this.form)
        this.loading = false
      })
    }
    },

    saveInstructor () {
      if (!this.$refs.form.validate()) return;
      this.form.avatar = this.newAvatar
      if (this.isCreate) {
        this.addInstructor(this.form).then((res) => {
          if(res.data.status)
          if(res.data.status == 'success')
            this.$router.push({ name: 'instructors' })
        })
      } else {
        console.log(this.form)
        this.updateInstructor({ id: this.$route.params.id, data: this.form }).then(() => {
          initialFetch();
        })
      }
    },

    removeInstructor (payload) {
      this.deleteInstructor({ id: payload.id })
      this.$router.push({name: 'instructors'})
    }
  },
  computed: {
    ...mapGetters("Instructors", ["getSingleInstructor"]),
    getImg() {
      if (this.newAvatar) {
        return URL.createObjectURL(this.newAvatar)
      } else {
        return `https://bact-back.usol.ca/${this.form.avatar}`;
      }
    }
  }
}
</script>