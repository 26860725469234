<template>
  <v-container class="d-flex flex-column justify-center">
    <v-progress-linear
      v-show="loading"
      indeterminate
      :color="this.primaryColor"
    ></v-progress-linear>
    <br>
    <SearchItems class="align-self-center" @search="search" @undoSearch="fetch"/>
    <Course 
    v-for="course in this.courses" :key="course.course_id"
    :course="course"
    :pending="pending"
    @confirm="openConfirm(course.course_id)"
    @reject="openReject(course.course_id)"
    @resubmit="openResubmit(course.course_id)"
    />
    <confirm-status
      v-if="statusConfirm >= 0"
      v-bind="{ id: statusConfirm, statusMethod: confirmType, isOpen: true }"
      @handleClose="statusConfirm = -1;"
      @handelRequest="changeStatus"
    ></confirm-status>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Course from './Components/Course'
import ConfirmStatus from './Components/confirmStatus';
import SearchItems from '../../Components/SearchItems'

export default {
  name: 'Courses',
  props: [
    "pending"
  ],
  data () {
    return {
      courses: [],
      loading: false,
      statusConfirm: -1,
      confirmType: ''
    }
  },
  components: {
    Course,
    SearchItems,
    ConfirmStatus
  },

  created () {
    this.fetch();
  },
  methods: {
    ...mapActions("Instructors/Courses", ["fetchCourses", "fetchCoursesWithSearch", "fetchPendingCourses", "fetchPendingCoursesWithSearch"]),
    ...mapActions("DashboardMain", ["updateStatus"]),

    openConfirm (id) {
      this.statusConfirm = id;
      this.confirmType = 'confirm'
    },

    openReject (id) {
      this.statusConfirm = id;
      this.confirmType = 'reject'
    },

    openResubmit (id) {
      this.statusConfirm = id;
      this.confirmType = 'resubmit'
    },

    fetch () {
      this.loading = true
      if (this.pending) {
        this.fetchPendingCourses(this.$route.params.id).then(() => {
          this.courses = this.getPendingCourses
          console.log(this.courses)
          this.loading = false
        })
      } else {
        this.fetchCourses(this.$route.params.id).then(() => {
          this.courses = this.getCourses
          this.loading = false
          console.log(this.courses)
        }) 
      }
    },

    search (payload) {
      console.log(payload)
      if (this.pending) {
        this.fetchPendingCoursesWithSearch({ searchTerm: payload.searchTerm, id: this.$route.params.id }).then(() => {
          this.courses = this.getPendingCourses
        })
      } else {
        this.fetchCoursesWithSearch({ searchTerm: payload.searchTerm, id: this.$route.params.id }).then(() => {
          this.courses = this.getCourses
        })
      }
      console.log(this.getCourses)
    },

    changeStatus (payload) {
      let status = '';
      if (payload.method === 'confirm') {
        payload.message = undefined;
        status = 'live';
      } else if (payload.method === 'reject') {
        status = 'denied';
      } else if (payload.method === 'resubmit') {
        status = 'resubmit';
      }
      this.updateStatus({ course_id: payload.id, status: status, description: payload.message }).then(() => {
        this.fetch();
        this.statusConfirm = -1
      })
    },
  },
  computed: mapGetters("Instructors/Courses", ["getCourses", "getPendingCourses"])
}
</script>