<template>
  <v-card class="student mb-3 pa-3" elevation="5">
      <v-layout row wrap class="pa-3">
        <v-flex xs12 md4>
          <div class="teal--text mb-1">{{ $t('instructors.studentsTab.studentName') }}</div>
          <div class="pr-4">{{ student.student_name }}</div>
        </v-flex>
        <v-flex xs12 md4>
          <div class="teal--text mb-1">{{ $t('instructors.studentsTab.studentEmail') }}</div>
          <div>{{ student.student_email }}</div>
        </v-flex>
        <v-flex xs12 md4>
          <div class="teal--text mb-1">{{ $t('instructors.studentsTab.studentCourses') }}</div>
          <div>{{ student.student_courses }}</div>
        </v-flex>
      </v-layout>
    </v-card>
</template>
<script>
import moment from "moment";
export default {
  name: 'Stuedent',
  props: [
    'student'
  ],
}
</script>
<style lang="scss" scoped>
.delete-icon:hover {
  cursor: pointer;
}
.student:nth-of-type(even) {
  border-left: 4px gray solid;
  border-right: 4px gray solid;
}
.student:nth-of-type(odd) {
  border-left: 4px teal solid;
  border-right: 4px teal solid;
}
</style>